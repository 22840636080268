import loadable from '@loadable/component'
import config from '/config';

export default {
	home: {
		path: '/',
		exact: true,
		layoutClass: 'home',
		component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/home')),
		regularAppHeader: true,
		head: {
			title: 'Bebeğim için her şey! Anne Bebek Ürünleri Mağazası',
			schema: JSON.stringify({
				"@context": "https://schema.org",
				"@type": "WebSite",
				"url": config.siteURL,
				"potentialAction": {
					"@type": "SearchAction",
					"target": {
						"@type": "EntryPoint",
						"urlTemplate": `${config.siteURL}/arama/{search_term_string}`
					},
					"query-input": "required name=search_term_string"
				}
			}),
		},
	},
	basket: {
		path: '/sepetim',
		exact: true,
		layout: 'checkout',
		head: {
			title: 'Sepetim',
		},
		component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/basket')),
	},
	checkout: {
		path: '/sepetim/odeme',
		head: {
			title: 'Ödeme',
			loaded: false,
		},
		requiresLogin: true,
		acceptsGuestLogin: true,
		loadingTitle: 'Ödeme',
		plainHeaderBackBtn: 'basket',
		layoutProps: {
			plainHeader: true,
			plainFooter: true,
		},
		component: loadable(() => import('/views/pages/checkout')),
	},
	checkoutSummary: {
		path: '/siparis-ozeti/:saleID/:saleCode',
		requiresLogin: true,
		acceptsGuestLogin: true,
		previousPage: 'home',
		head: {
			title: 'Sipariş Özeti',
		},
		layoutProps: {
			plainHeader: true,
			plainFooter: true,
		},
		component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/checkout-summary')),
	},
	productDetail: {
		path: '/:slug-p-:id/:serialSlug?/:serialID?',
		head: {
			title: 'Ürün',
		},
		component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/product-detail')),
	},

	// productComparison: {
	// 	path: '/urun-karsilastirma',
	// 	component: 'ProductComparison',
	// 	head: {
	// 		title: 'Ürün Karşılaştırma',
	// 	},
	// },
	productComparison: {
		path: '/urun-karsilastirma',
		head: {
			title: 'Ürün Karşılaştırma',
		},
		component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/product-comparison')),
	},

	// Listing Pages
	category: {
		path: '/:slug-c-:id/:query?',
		loadingTitle: 'Kategori Getiriliyor...',
		head: {
			title: 'Kategori',
			loaded: false,
		},
		component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/category')),
		plainHeaderSearch: true,
	},
	search: {
		path: '/arama/:search/:query?',
		loadingTitle: 'Arama Sonuçları Getiriliyor...',
		head: {
			title: 'Arama',
			loaded: false,
		},
		component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/search')),
		plainHeaderSearch: true,
	},
	customList: {
		path: '/:slug-cl-:id/:query?',
		head: {
			title: 'Liste',
		},
		plainHeaderSearch: true,
		component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/customlist')),
	},
	brands: {
		path: '/markalar',
		layoutClass: 'brands',
		head: {
			title: 'Markalar',
		},
		component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/brands')),
	},
	brand: {
		path: '/:slug-m-:id/:query?',
		loadingTitle: 'Marka Ürünleri Getiriliyor...',
		head: {
			title: 'Marka',
			loaded: false,
		},
		plainHeaderSearch: true,
		component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/brand')),
	},

	// Authentication
	login: {
		path: '/giris/:redirect?',
		head: {
			title: 'Üye Girişi',
		},
		layoutProps: {
			hideHeader: true,
			hideFooter: true,
		},
		component: loadable(() => import('/views/pages/auth')),
	},
	register: {
		path: '/kayit-ol/:redirect?',
		head: {
			title: 'Yeni Üye Kaydı',
		},
		layoutProps: {
			hideHeader: true,
			hideFooter: true,
		},
		component: loadable(() => import('/views/pages/auth')),
	},

	// About Pages
	about: {
		path: '/hakkimizda',
		layout: 'about',
		loadingTitle: 'Babymall Hakkında',
		head: {
			title: 'Babymall Hakkında',
			loaded: false,
		},
		layoutProps: {
			sectionClass: 'about',
		},
		component: loadable(() => import('/views/pages/about')),
	},
	agreement: {
		path: '/sozlesmeler/:type',
		layout: 'about',
		loadingTitle: 'Sözleşme Getiriliyor...',
		head: {
			title: 'Sözleşme',
			loaded: false,
		},
		layoutProps: {
			sectionClass: 'about',
		},
		component: loadable(() => import('/views/pages/agreement')),
	},
	contact: {
		path: '/iletisim-bilgileri',
		layout: 'about',
		head: {
			title: 'İletişim Bilgileri'
		},
		layoutProps: {
			sectionClass: 'contact',
		},
		component: loadable(() => import('/views/pages/contact')),
	},
	cookies: {
		path: '/cerez-politikasi',
		layout: 'about',
		head: {
			title: 'Çerez Politikası'
		},
		layoutProps: {
			sectionClass: 'cookies',
		},
		component: loadable(() => import('/views/pages/cookies')),
	},
	announcements: {
		path: '/duyurular',
		layout: 'about',
		head: {
			title: 'Duyurular'
		},
		layoutProps: {
			sectionClass: 'announcements',
		},
		component: loadable(() => import('/views/pages/announcements')),
	},

	// Campaigns
	campaigns: {
		path: '/kampanyalar',
		exact: true,
		head: {
			title: 'Kampanyalar',
		},
		component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/campaigns')),
	},
	campaignDetail: {
		path: '/kampanyalar/:id',
		head: {
			title: 'Kampanyalar',
		},
		component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/campaign-detail')),
	},

	// Account
	account: {
		path: '/hesabim',
		requiresLogin: true,
		redirect: {
			key: 'account.info',
			params: {}
		},
		children: {
			info: {
				path: '/hesabim/bilgilerim',
				layout: 'account',
				requiresLogin: true,
				head: {
					title: 'Hesap Bilgilerim',
				},
				component: loadable(() => import('/views/pages/account/info')),
				layoutProps: {
					sectionClass: 'info',
				}
			},
			orders: {
				path: '/hesabim/siparislerim',
				layout: 'account',
				requiresLogin: true,
				head: {
					title: 'Siparişlerim',
				},
				component: loadable(() => import('/views/pages/account/orders')),
				layoutProps: {
					sectionClass: 'orders',
				}
			},
			orderDetail: {
				path: '/hesabim/siparislerim/:id',
				layout: 'account',
				requiresLogin: true,
				head: {
					title: 'Siparişlerim',
				},
				component: loadable(() => import('/views/pages/account/orders')),
				layoutProps: {
					sectionClass: 'orders',
				}
			},
			children: {
				path: '/hesabim/cocuk-bilgilerim',
				layout: 'account',
				requiresLogin: true,
				head: {
					title: 'Bebek Bilgilerim',
				},
				component: loadable(() => import('/views/pages/account/children')),
				layoutProps: {
					sectionClass: 'children',
				}
			},
			addresses: {
				path: '/hesabim/adreslerim',
				layout: 'account',
				requiresLogin: true,
				head: {
					title: 'Adreslerim',
				},
				component: loadable(() => import('/views/pages/account/addresses')),
				layoutProps: {
					sectionClass: 'addresses',
				}
			},
			giftCodes: {
				path: '/hesabim/hediye-ceklerim/:mode?',
				layout: 'account',
				requiresLogin: true,
				head: {
					title: 'Hediye Çeklerim',
				},
				component: loadable(() => import('/views/pages/account/gift-codes')),
				layoutProps: {
					sectionClass: 'gift-codes',
				}
			},
			loyaltyInfo: {
				path: '/hesabim/kalbim-kart-programi',
				layout: 'account',
				requiresLogin: true,
				head: {
					title: 'Kalbim Kart Programı',
				},
				component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/account/loyalty-info')),
				layoutProps: {
					sectionClass: 'loyalty-info',
				}
			},
			reviews: {
				path: '/hesabim/degerlendirmelerim/:mode?',
				layout: 'account',
				requiresLogin: true,
				head: {
					title: 'Değerlendirmelerim',
				},
				component: loadable(() => import('/views/pages/account/reviews')),
				layoutProps: {
					sectionClass: 'reviews',
				}
			},
			favorites: {
				path: '/hesabim/favorilerim',
				layout: 'account',
				requiresLogin: true,
				head: {
					title: 'Favorilerim',
				},
				component: loadable(() => import('/views/pages/account/favorites')),
				layoutProps: {
					sectionClass: 'favorites',
				}
			},
			alarms: {
				path: '/hesabim/alarmlarim/:mode?',
				layout: 'account',
				requiresLogin: true,
				head: {
					title: 'Alarmlarım',
				},
				component: loadable(() => import('/views/pages/account/alarms')),
				layoutProps: {
					sectionClass: 'alarms',
				}
			},
			messageDetail: {
				path: '/hesabim/mesajlarim/:id',
				layout: 'account',
				requiresLogin: true,
				head: {
					title: 'Mesajlarım',
				},
				component: loadable(() => import('/views/pages/account/message-detail')),
				layoutProps: {
					sectionClass: 'message-detail',
				}
			},
			messages: {
				exact: true,
				path: '/hesabim/mesajlarim',
				layout: 'account',
				requiresLogin: true,
				head: {
					title: 'Mesajlarım',
				},
				component: loadable(() => import('/views/pages/account/messages')),
				layoutProps: {
					sectionClass: 'messages',
				}
			},
		}
	},

	// ETC
	faq: {
		path: '/sss',
		exact: true,
		loadingTitle: 'Sıkça Sorulan Sorular',
		head: {
			title: 'Sıkça Sorulan Sorular',
			loaded: false,
		},
		component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/faq')),
	},
	faqDetail: {
		path: '/sss/:slug/:id?',
		loadingTitle: 'Sıkça Sorulan Sorular',
		head: {
			title: 'Sıkça Sorulan Sorular',
			loaded: false,
		},
		component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/faq')),
	},
	stores: {
		path: '/magazalarimiz/:slug?/:id?',
		head: {
			title: 'Mağazalarımız',
		},
		component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/stores')),
	},

	// Segmentify
	...((config.segmentify?.enabled && config.segmentify.apiKey) ? {
		segmentifySearchandising: {
			path: `/${config.segmentify.urlKey ?? 'segmentify'}`,
			head: {
				title: 'Arama',
				segmentifyStyles: {
					key: 'link',
					props: {
						rel: 'stylesheet',
						href: `https://cdn.segmentify.com/${config.segmentify.apiKey}/search.css`
					},
				},
				segmentifySearchScript: {
					key: 'script',
					props: {
						src: `https://cdn.segmentify.com/${config.segmentify.apiKey}/search.js`
					},
				},
			},
			layoutProps: {
				sectionClass: 'segmentify',
			},
			component: loadable(() => import('/views/pages/segmentify-searchandising')),
		},
	} : {}),

	// Errors
	notFound: {
		path: '*',
		status: 404,
		head: {
			title: 'Sayfa Bulunamadı',
		},
		component: loadable(() => import('/views/pages/not-found')),
	},
	error: {
		path: '*',
		status: 500,
		head: {
			title: 'Bir Hata İle Karşılaşıldı',
		},
		component: loadable(() => import('/views/pages/error')),
	}
}