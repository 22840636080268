import 'allsettled-polyfill';
import { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import { BasketContext } from './basket-context';
import { defaultState } from '.';
import omit from 'lodash/omit';
import useDebounce from '@wearetla/tla-essentials-tools/hooks/debounce';
import config from '/config';

// Services
import saleServices from '/services/sale';
import basketServices from '/services/basket';

// Context
import { useStorage } from '@wearetla/tla-essentials-tools/utilities/storage';
import { useModals } from '@wearetla/tla-essentials-tools/utilities/modals';
import { useGlobalEvents } from '@wearetla/tla-essentials-tools/utilities/global-events';

// Functions
import formatNumber from '@wearetla/tla-essentials-tools/functions/format-number';


const useBasketEvents = ((indexedProducts, initialized, modalBehaviorData) => {
	const previousProducts = useRef(false);
	const { triggerEvent } = useGlobalEvents();
	const { openModal } = useModals();

	useEffect(() => {
		if(initialized) {
			if(previousProducts.current) {
				const flatProducts = {
					...Object.entries(previousProducts.current).reduce((products, [serial, product]) => ({
						...products,
						[serial]: {
							...product,
							basket_quantity: 0,
						}, 
					}),
					{}),
					...indexedProducts,
				}

				let modalProduct = null;

				for(const [serial, product] of Object.entries(flatProducts)) {
					const oldQuantity = previousProducts.current[serial]?.basket_quantity ? parseFloat(previousProducts.current[serial].basket_quantity) : 0;
					const newQuantity = parseFloat(product.basket_quantity);

					if(newQuantity > oldQuantity) {
						triggerEvent('productBasketAdd', { product, quantity: parseFloat((newQuantity - oldQuantity).toFixed(3)) });
						triggerEvent('productBasketQuantityChange', { product, quantity: newQuantity });

						if(!modalProduct && product.total_price?.original && modalBehaviorData?.[product.serial_id] !== false) {
							modalProduct = { ...product };
						}
					}
					else if(oldQuantity > newQuantity) {
						triggerEvent('productBasketRemove', { product, quantity: parseFloat((oldQuantity - newQuantity).toFixed(3)) });
						triggerEvent('productBasketQuantityChange', { product, quantity: newQuantity });
					}

					if(modalProduct) {
						openModal('product-basket-message',  {
							product: modalProduct,
							serial: modalProduct.serial_id,
						});
					}
				}
			}
	
			previousProducts.current = indexedProducts;
		}
	}, [indexedProducts, initialized])
})

export const BasketProvider = ({ children }) => {
	const { openModal } = useModals();
	const { guestToken, storeId } = useStorage();

	const [initialized, setInitialized] = useState(defaultState.initialized);
	const [products, setProducts] = useState(defaultState.products);
	const [summary, setSummary] = useState(defaultState.summary);
	const [fetchedStoreId, setFetchedStoreId] = useState(false);
	const [basketTimestamp, setBasketTimestamp] = useState(false);
	const [busy, setBusy] = useState(false);
	const [modalBehaviorData, setModalBehaviorData] = useState({});

	const updateQueueRef = useRef({})

	const [updateQueue, setUpdateQueueRaw] = useState(updateQueueRef.current);
	const setUpdateQueue = (newQueue) => {
		updateQueueRef.current = newQueue;
		setUpdateQueueRaw(updateQueueRef.current);
	}
	const debouncedUpdateQueue = useDebounce(updateQueue, 200, updateQueueRef.current);

	const indexedProducts = useMemo(() => {
		const newIndexedProducts = {}
		for(const product of products) {
			newIndexedProducts[product.serial_id] = {...product};
		}

		return newIndexedProducts;
	}, [products])

	useBasketEvents(indexedProducts, initialized, modalBehaviorData);

	useEffect(() => {
		const serials = Object.keys(debouncedUpdateQueue);
		if(serials.length > 0) {
			setBusy(true);

			const promises = serials.map(serial => {
				return (debouncedUpdateQueue[serial].quantity > 0 ?
					basketServices.addProduct(serial, debouncedUpdateQueue[serial].id, debouncedUpdateQueue[serial].quantity)
					:
					indexedProducts[serial] ? 
						basketServices.removeProduct(indexedProducts[serial].basket_id) :
						(() => (new Promise((resolve) => {
							resolve(true);
						})))()
				)
			});

			Promise.allSettled(promises).catch(() => {
				setBusy(false);
				setUpdateQueue({});
			}).then((data) => {
				const errors = data.map((result, nth) => result.reason ? { ...result, promiseIndex: nth } : false).reduce((products, result) => {
					const message = typeof result.reason === 'string' ? result.reason : result.reason?.error ? result.reason?.error.join(', ') : false;

					if(message) {
						return [
							...products,
							{
								message: message,
								product: debouncedUpdateQueue[serials[result.promiseIndex]],
							}
						];
					}
					return products;
				}, []);

				errors.map((error, nth) => {
					openModal('product-basket-message',  {
						product: error.product,
						serial: error.product.serial_id,
						message: error.message,
						icon: 'exclamation',
						layer: (nth + 2),
					})
				});
				
				getBasketData().catch(() => {
				}).finally(() => {
					setBusy(false);
					setUpdateQueue({});
				})
			})

		}
	}, [debouncedUpdateQueue, getBasketData])

	useEffect(() => {
		if(!initialized && guestToken) {
			getBasketData();
		}
		else if(initialized && !guestToken) {
			resetBasketData();
		}
	}, [initialized, guestToken, getBasketData, resetBasketData]);

	useEffect(() => {
		if(fetchedStoreId && storeId !== fetchedStoreId) {
			clearBasket();
		}
	}, [fetchedStoreId, storeId, clearBasket])

	const resetBasketData = useCallback(() => {
		setProducts(defaultState.products);
		setSummary(defaultState.summary);
		setFetchedStoreId(storeId);
		setInitialized(false);
	}, [storeId]);

	const setModalBehavior = (serialId, showModal) => {
		setModalBehaviorData(curModalBehavior => ({
				...curModalBehavior,
				[serialId]: showModal,
		}));
	}

	const getBasketData = useCallback(() => {
		return new Promise((resolve, reject) => {
			basketServices.get().then(basketData => {
				const { bagProduct, deliveryProduct, filteredProducts } = basketData.products.reduce((newData, product) => {
					if(product.product_code === config.bagProductErpCode) {
						return {
							bagProduct: product,
							deliveryProduct: newData.deliveryProduct,
							filteredProducts: newData.filteredProducts,
						}
					}
					else if(product.product_code === config.deliveryProductErpCode) {
						return {
							bagProduct: newData.bagProduct,
							deliveryProduct: product,
							filteredProducts: newData.filteredProducts,
						}
					}
					else {
						return {
							bagProduct: newData.bagProduct,
							deliveryProduct: newData.deliveryProduct,
							filteredProducts: [...newData.filteredProducts, product],
						}
					}
				}, { bagProduct: false, deliveryProduct: false, filteredProducts: [] })
				setProducts(filteredProducts);

				const newSummary = {
					...basketData.summary,
					bagProduct,
					deliveryProduct,
				}

				if(deliveryProduct) {
					const newPrice = newSummary.price.original - deliveryProduct.total_price.original;
					const newPriceStr = formatNumber(newPrice);
					const [newPriceWhole, newPriceFraction] = newPriceStr.split(',');
					newSummary.price = {
						...newSummary.price,
						original: newPrice,
						original_str: newPriceStr,
						whole: parseInt(newPriceWhole),
						whole_str: newPriceWhole,
						fraction: parseInt(newPriceFraction),
						fraction_str: newPriceFraction,
					}
				}
				if(bagProduct) {
					const newPrice = newSummary.price.original - bagProduct.total_price.original;
					const newPriceStr = formatNumber(newPrice);
					const [newPriceWhole, newPriceFraction] = newPriceStr.split(',');
					newSummary.price = {
						...newSummary.price,
						original: newPrice,
						original_str: newPriceStr,
						whole: parseInt(newPriceWhole),
						whole_str: newPriceWhole,
						fraction: parseInt(newPriceFraction),
						fraction_str: newPriceFraction,
					}
				}

				setSummary(newSummary);
				setFetchedStoreId(storeId);
				setInitialized(true);
				resolve(basketData);
			}).catch((e) => {
				reject(e);
			}).finally(() => {
				setBasketTimestamp(new Date().getTime().toString());
			})
		});
	}, [storeId]);

	const clearBasket = useCallback(() => {
		return new Promise((resolve) => {
			setBusy(true);
			basketServices.clear().catch((feedback) => {
				openModal('alert', { feedback: feedback });
			}).finally(() => {
				getBasketData().then((basketData) => {
					setBusy(false);
					resolve(basketData);
				});
			})
		});
	}, [getBasketData]);

	const updateProductQuantity = (product, quantity, showModal = true) => {
		return new Promise((resolve) => {
			const productSerial = product.serial_id;

			setModalBehavior(product.serial_id, showModal);
			// No changes on quantity compared to latest fetched basket.
			if(quantity === 0 && !indexedProducts[productSerial] || quantity === indexedProducts[productSerial]?.basket_quantity) {
				setUpdateQueue(omit(updateQueueRef.current, productSerial));
				resolve();
			}
			else {
				setUpdateQueue({ ...updateQueueRef.current, [productSerial]: { ...product, quantity: quantity, id: product.id } });
				resolve();
			}
		})
	}

	const prepare = ({ addExchangeCard } = { addExchangeCard: false}) => {
		return new Promise((resolve, reject) => {
			saleServices.prepare({
				exchange_card: addExchangeCard,
			}).then((payload) => {
					window.localStorage?.setItem('checkout-sale-id', payload.id);
	
					if(payload.has_support_products) {
						window.localStorage?.setItem('checkout-sale-has-support-products', 1);
					}
					else {
						window.localStorage?.removeItem('checkout-sale-has-support-products', 1);
					}

					resolve(payload);
			}).catch((e) => {
				reject(e);
			})
		})
	}

	return <BasketContext.Provider value={{
		initialized,
		products,
		summary,
		indexedProducts,
		busy,
		updateProductQuantity,
		clearBasket,
		getBasketData,
		prepare,
		basketTimestamp,
	}}>
		{children}
	</BasketContext.Provider>
}